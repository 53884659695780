import React from "react";
import L from "leaflet";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import ProjectFromState from "./project_from_state";
import { BoundingBox } from "biohub-model";
import { getProjectsOnProjectTree } from "../../../../../store/reducers/projectTreeReducer";

export default (props: {
  onClickNotSelectedArea: (projectId: string, areaId: string) => void;
  moveToBoundingBox: (boundingBox: BoundingBox) => void;
  onClickWaypoint: (projectId: string, areaId: string, waypointIndex: number) => void;
  map: L.Map;
  setPolygonOrPolylineRef: (id: string, ref: L.Polyline | L.Polygon | null, zIndex: number) => void;
  onRenderPolygonOrPolyline: (id: string, zIndex: number) => void;
}): JSX.Element => {
  const projectsIds = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const projectList = getProjectsOnProjectTree(
      projectTreeState.projectList,
      projectTreeState.visualizationMode,
      projectTreeState.visualizationModeConsideringSubItems,
      ""
    );

    return projectList.map((project) => project.id);
  });

  return (
    <>
      {projectsIds.map((projectId) => (
        <ProjectFromState
          projectId={projectId}
          onClickNotSelectedArea={(areaId) => props.onClickNotSelectedArea(projectId, areaId)}
          onClickWaypoint={(areaId, waypointIndex) =>
            props.onClickWaypoint(projectId, areaId, waypointIndex)
          }
          moveToBoundingBox={props.moveToBoundingBox}
          map={props.map}
          onRenderPolygonOrPolyline={props.onRenderPolygonOrPolyline}
          setPolygonOrPolylineRef={props.setPolygonOrPolylineRef}
        />
      ))}
    </>
  );
};
