import {
  Area,
  AreaConfig,
  BoundingBox,
  CompletePlannedArea,
  CpuLog,
  CurveMode,
  ElevationData,
  Flight,
  HeadingMode,
  Location,
  PlannedArea,
  PlannedRoute,
  Profile,
  Project,
  ReleaserAction,
  ReleaserConfiguration,
  Role,
  Waypoint,
} from "biohub-model";
import _ from "lodash";
import { IntlShape } from "react-intl";
import { v4 as uuid } from "uuid";
import { BaseMapTypeId } from "../../components/map/BaseMap";
import BaseMapController from "../../components/map/BaseMapController";
import {
  bearingBetween,
  boundingBoxDiagonalSize,
  boundingBoxForAreas,
  calculateAreaHa,
  centerOfBoundingBox,
  chooseDefaultHomePoint,
  distanceBetween,
  fixHeadingForWaypoints,
  isPointInsidePolygon,
  isPointsInsideBoundingBox,
} from "../../core/geometricFunctions";
import { BiohubError, BiohubErrorCode } from "../../services/axios/BiohubApi";
import LocationService from "../../services/LocationService";
import ProjectService, {
  calculateCurveOfAllWaypoints,
  executeMissionPlanner,
  getMissionPlannerRouteUsingBasePointAndRouteAngle,
  plannedRouteControlUpdate,
  ProjectWithAreaCount,
} from "../../services/ProjectService";
import { SystemCombinedState, SystemState } from "../reducers/systemReducer";
import { SystemThunk } from "../systemThunk";
import { Dispatch } from "./systemActions";
import { fetchWeatherData } from "./weatherActions";

import {
  AreaInProjectTree,
  castProjectInProjectTreeToProject,
  EditingArea,
  EditingAreaType,
  FlightInProjectTree,
  ProjectInProjectTree,
  ProjectTreeMapState,
  ProjectTreeState,
  VisualizationMode,
} from "../reducers/projectTreeReducer";
import KmklService, { ExportKmlTypes } from "../../services/KmklService";
import { ImportedRouteData } from "../../services/routePlanner/importGeographicData";

import CpuLogService from "../../services/CpuLogService";
import ElevationService from "../../services/routePlanner/ElevationService";

import * as geolib from "geolib";
import { ProfileState } from "../reducers/profileReducer";
import { getCacheDataService } from "../../services/Persistence/CacheDataService";
import { mapMaximumZoom, mapMinimumZoom } from "../../components/map/impl/MapImplLeaflet";

var baseMapController: BaseMapController | null = null;

const numberOfParallelProcessingProjects = 3;

export const SET_MAP_INITIALIZED = "SET_MAP_INITIALIZED";
export const SET_MAP_STATE = "SET_MAP_STATE";

export const SET_LOADING_PROJECTS = "SET_LOADING_PROJECTS";
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const PUT_PROJECT = "PUT_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const RESET_PROJECT_WITH_LOADED_AREAS_COUNT = "RESET_PROJECT_WITH_LOADED_AREAS_COUNT";
export const INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT =
  "INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT";

export const SET_PROJECT_TREE_ERROR = "SET_PROJECT_TREE_ERROR";

export const SET_ITEMS_VISIBILITY = "SET_ITEMS_VISIBILITY";
export const SET_PROJECT_VISIBILITY = "SET_PROJECT_VISIBILITY";
export const SET_AREA_VISIBILITY = "SET_AREA_VISIBILITY";
export const SET_FLIGHT_VISIBILITY = "SET_FLIGHT_VISIBILITY";

export const SELECT_PROJECT = "SELECT_PROJECT";
export const EXPAND_PROJECT = "EXPAND_PROJECT";
export const COLLAPSE_PROJECT = "COLLAPSE_PROJECT";

export const SELECT_AREA = "SELECT_AREA";
export const EXPAND_AREA = "EXPAND_AREA";
export const COLLAPSE_AREA = "COLLAPSE_AREA";

export const SET_PROJECT_LOADING_AREAS = "SET_PROJECT_LOADING_AREAS";
export const SET_PROJECTS_LOADING_AREAS = "SET_PROJECTS_LOADING_AREAS";

export const SET_PROJECT_AREA_LIST = "SET_PROJECT_AREA_LIST";
export const SET_PROJECTS_AREA_LIST = "SET_PROJECTS_AREA_LIST";
export const PUT_PROJECT_AREA = "PUT_PROJECT_AREA";
export const UPDATE_PROJECT_AREA = "UPDATE_PROJECT_AREA";
export const DELETE_AREA = "DELETE_AREA";

export const SET_AREA_LOADING_FLIGHTS = "SET_AREA_LOADING_FLIGHTS";
export const SET_PROJECT_LOADING_FLIGHTS = "SET_PROJECT_LOADING_FLIGHTS";
export const SET_AREA_FLIGHT_LIST = "SET_AREA_FLIGHT_LIST";
export const SET_PROJECT_FLIGHT_LIST = "SET_PROJECT_FLIGHT_LIST";

export const SET_CREATING_PROJECT = "SET_CREATING_PROJECT";
export const SET_CREATING_AREA = "SET_CREATING_AREA";

export const SET_UPDATING_PROJECT = "SET_UPDATING_PROJECT";
export const SET_UPDATING_AREA = "SET_UPDATING_AREA";

export const SET_RUNNING_MISSION_PLANNER = "SET_RUNNING_MISSION_PLANNER";

export const SET_USER_LOCATION = "SET_USER_LOCATION";

export const SET_DELETING_PROJECT = "SET_DELETING_PROJECT";
export const SET_DELETING_AREA = "SET_DELETING_AREA";

export const SELECT_VIEW_FLIGHT_PLAN = "SELECT_VIEW_FLIGHT_PLAN";
export const QUIT_VIEW_FLIGHT_PLAN = "QUIT_VIEW_FLIGHT_PLAN";

export const ENTER_EDIT_AREA_MODE = "ENTER_EDIT_AREA_MODE";
export const UPDATE_EDIT_AREA_DATA = "UPDATE_EDIT_AREA_DATA";
export const QUIT_EDIT_AREA_MODE = "QUIT_EDIT_AREA_MODE";

export const SET_MAP_TYPE = "SET_MAP_TYPE";

export const SET_COPIED_AREA = "SET_COPIED_AREA";

export const UPDATE_DIAGONAL_SCREEN_SIZE = "UPDATE_DIAGONAL_SCREEN_SIZE";

export const SET_MOVING_MAP_CAMERA = "SET_MOVING_MAP_CAMERA";

export const SET_AREA_BEING_EXPORTED_AS_KML = "SET_AREA_BEING_EXPORTED_AS_KML";
export const SET_FLIGHT_BEING_EXPORTED_AS_KML = "SET_FLIGHT_BEING_EXPORTED_AS_KML";

export const SET_LOADING_CPU_LOGS = "SET_LOADING_CPU_LOGS";
export const SET_CPU_LOGS_LIST = "SET_CPU_LOGS_LIST";
export const EXPAND_CPUS_LOGS = "EXPAND_CPUS_LOGS";
export const COLLAPSE_CPU_LOGS = "COLLAPSE_CPU_LOGS";

export const SET_SHOWING_MAP_LABELS = "SET_SHOWING_MAP_LABELS";

export const FETCH_MAP_PIXELS_DIMENSIONS = "FETCH_MAP_PIXELS_DIMENSIONS";

export const SET_SELECTED_WAYPOINT_INDEXES = "SET_SELECTED_WAYPOINT_INDEXES";

export const SET_PRESSING_CTRL_KEY = "SET_PRESSING_CTRL_KEY";

export const SET_VISUALIZATION_MODE = "SET_VISUALIZATION_MODE";

export const SET_EXPANDED_PROJECTS = "SET_EXPANDED_PROJECTS";

export const FETCH_PROJECT_TREE_SCROLL_POSITION = "FETCH_PROJECT_TREE_SCROLL_POSITION";

export type ProjectTreeActions =
  | { type: typeof SET_MAP_INITIALIZED }
  | {
      type: typeof SET_MAP_STATE;
      payload: Partial<Omit<ProjectTreeMapState, "mapTypeId">>;
    }
  | {
      type: typeof SET_LOADING_PROJECTS;
      payload: {
        loading: boolean;
      };
    }
  | {
      type: typeof SET_PROJECT_LIST;
      payload: {
        projects: ProjectWithAreaCount[];
      };
    }
  | {
      type: typeof PUT_PROJECT;
      payload: {
        project: ProjectWithAreaCount;
      };
    }
  | {
      type: typeof UPDATE_PROJECT;
      payload: {
        project: Partial<ProjectWithAreaCount>;
      };
    }
  | {
      type: typeof DELETE_PROJECT;
      payload: {
        projectId: string;
      };
    }
  | {
      type: typeof RESET_PROJECT_WITH_LOADED_AREAS_COUNT;
    }
  | {
      type: typeof INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT;
      payload: {
        amount: number;
      };
    }
  | {
      type: typeof SET_PROJECT_TREE_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof SET_ITEMS_VISIBILITY;
      payload: {
        visibility: boolean;
      };
    }
  | {
      type: typeof SET_PROJECT_VISIBILITY;
      payload: {
        projectId: string;
        visibility: boolean;
      };
    }
  | {
      type: typeof SET_AREA_VISIBILITY;
      payload: {
        projectId: string;
        areaId: string;
        visibility: boolean;
      };
    }
  | {
      type: typeof SET_FLIGHT_VISIBILITY;
      payload: {
        projectId: string;
        areaId: string;
        flightId: string;
        visibility: boolean;
      };
    }
  | {
      type: typeof SELECT_PROJECT;
      payload: {
        projectId: string | null;
      };
    }
  | {
      type: typeof EXPAND_PROJECT;
      payload: {
        projectId: string;
      };
    }
  | {
      type: typeof COLLAPSE_PROJECT;
      payload: {
        projectId: string;
      };
    }
  | {
      type: typeof SELECT_AREA;
      payload: {
        projectId: string;
        areaId: string | null;
      };
    }
  | {
      type: typeof EXPAND_AREA;
      payload: {
        projectId: string;
        areaId: string;
      };
    }
  | {
      type: typeof COLLAPSE_AREA;
      payload: {
        projectId: string;
        areaId: string;
      };
    }
  | {
      type: typeof SET_PROJECT_LOADING_AREAS;
      payload: {
        projectId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_PROJECTS_LOADING_AREAS;
      payload: {
        projectIds: string[];
        loading: boolean;
      };
    }
  | {
      type: typeof SET_PROJECT_AREA_LIST;
      payload: {
        projectId: string;
        areas: Area[];
      };
    }
  | {
      type: typeof SET_PROJECTS_AREA_LIST;
      payload: {
        projects: {
          projectId: string;
          areas: Area[];
        }[];
      };
    }
  | {
      type: typeof PUT_PROJECT_AREA;
      payload: {
        projectId: string;
        area: Area;
      };
    }
  | {
      type: typeof UPDATE_PROJECT_AREA;
      payload: {
        projectId: string;
        area: Partial<Area>;
      };
    }
  | {
      type: typeof DELETE_AREA;
      payload: {
        projectId: string;
        areaId: string;
      };
    }
  | {
      type: typeof SET_AREA_LOADING_FLIGHTS;
      payload: {
        projectId: string;
        areaId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_PROJECT_LOADING_FLIGHTS;
      payload: {
        projectId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_AREA_FLIGHT_LIST;
      payload: {
        projectId: string;
        areaId: string;
        flights: Flight[];
      };
    }
  | {
      type: typeof SET_CREATING_PROJECT;
      payload: {
        creating: boolean;
        projectId: string;
      };
    }
  | {
      type: typeof SET_CREATING_AREA;
      payload: {
        projectId: string;
        creating: boolean;
        areaId: string;
      };
    }
  | {
      type: typeof SET_UPDATING_PROJECT;
      payload: {
        updating: boolean;
        projectId: string;
      };
    }
  | {
      type: typeof SET_UPDATING_AREA;
      payload: {
        projectId: string;
        areaId: string;
        updating: boolean;
      };
    }
  | {
      type: typeof SET_RUNNING_MISSION_PLANNER;
      payload: {
        projectId: string;
        areaId: string;
        planning: boolean;
      };
    }
  | {
      type: typeof SET_USER_LOCATION;
      payload: {
        userLocation: Location | null;
      };
    }
  | {
      type: typeof SET_DELETING_PROJECT;
      payload: {
        projectId: string;
        deleting: boolean;
      };
    }
  | {
      type: typeof SET_DELETING_AREA;
      payload: {
        projectId: string;
        areaId: string;
        deleting: boolean;
      };
    }
  | {
      type: typeof SELECT_VIEW_FLIGHT_PLAN;
      payload: {
        projectId: string;
        areaId: string;
        plan: CompletePlannedArea;
      };
    }
  | {
      type: typeof QUIT_VIEW_FLIGHT_PLAN;
      payload: {
        projectId: string;
        areaId: string;
      };
    }
  | {
      type: typeof ENTER_EDIT_AREA_MODE;
      payload:
        | {
            type: "create-project";
            projectInfo: CreteProjectInfo;
            areaName: string;
          }
        | {
            type: "create-area";
            projectId: string;
            areaName: string;
            areaConfig: AreaConfigWithoutId;
            configuredReleasers: ReleaserConfiguration[];
          }
        | {
            type: "edit-points" | "edit-route" | "manual-route" | "edit-polygon";
            area: Area;
          }
        | {
            type: "rotate-plan";
            area: Area;
            basePoint: Location | undefined;
            routeAngle: number;
            homePoint: Location | undefined;
            waypoints: Waypoint[];
          };
    }
  | {
      type: typeof UPDATE_EDIT_AREA_DATA;
      payload: {
        editingArea: EditingArea;
      };
    }
  | {
      type: typeof QUIT_EDIT_AREA_MODE;
    }
  | {
      type: typeof SET_MAP_TYPE;
      payload: {
        mapType: BaseMapTypeId;
      };
    }
  | {
      type: typeof SET_COPIED_AREA;
      payload: {
        copiedArea: AreaInProjectTree | undefined;
      };
    }
  | {
      type: typeof UPDATE_DIAGONAL_SCREEN_SIZE;
      payload: { diagonalScreenSize: number };
    }
  | {
      type: typeof SET_MOVING_MAP_CAMERA;
      payload: { moving: boolean };
    }
  | {
      type: typeof SET_AREA_BEING_EXPORTED_AS_KML;
      payload: {
        projectId: string;
        areaId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_FLIGHT_BEING_EXPORTED_AS_KML;
      payload: {
        projectId: string;
        areaId: string;
        flightId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_PROJECT_FLIGHT_LIST;
      payload: {
        projectId: string;
        flights: Flight[];
      };
    }
  | {
      type: typeof SET_LOADING_CPU_LOGS;
      payload: {
        loading: boolean;
      };
    }
  | {
      type: typeof SET_CPU_LOGS_LIST;
      payload: {
        cpuLogs: CpuLog[];
      };
    }
  | {
      type: typeof EXPAND_CPUS_LOGS;
    }
  | {
      type: typeof COLLAPSE_CPU_LOGS;
    }
  | {
      type: typeof SET_SHOWING_MAP_LABELS;
      payload: {
        showing: boolean;
      };
    }
  | {
      type: typeof FETCH_MAP_PIXELS_DIMENSIONS;
      payload: {
        pixelsWidth: number;
        pixelsHeight: number;
      };
    }
  | {
      type: typeof SET_SELECTED_WAYPOINT_INDEXES;
      payload:
        | {
            projectId: string;
            areaId: string;
            waypointIndexes: number[];
          }
        | undefined;
    }
  | {
      type: typeof SET_PRESSING_CTRL_KEY;
      payload: {
        pressing: boolean;
      };
    }
  | {
      type: typeof SET_VISUALIZATION_MODE;
      payload: {
        mode: VisualizationMode;
        consideringSubItems: boolean;
      };
    }
  | {
      type: typeof SET_EXPANDED_PROJECTS;
      payload: {
        expanded: boolean;
      };
    }
  | {
      type: typeof FETCH_PROJECT_TREE_SCROLL_POSITION;
      payload: {
        position: number;
      };
    };

export function initMap(mapController: BaseMapController | null): SystemThunk {
  return async (dispatch) => {
    baseMapController = mapController;

    dispatch({
      type: SET_MAP_INITIALIZED,
    });
    dispatch(onMapZoomChanged());

    dispatch(goToUserLocationProcedures());
  };
}

export function goToUserLocationProcedures(zoom?: number): SystemThunk {
  return async (dispatch) => {
    const userPosition = await LocationService.getCurrentUserLocation();
    if (userPosition !== null) {
      dispatch(setUserLocation(userPosition));
      dispatch(moveToCoordinates(userPosition, zoom));
    }
  };
}

export function loadProjects(): SystemThunk {
  return async (dispatch) => {
    const cacheService = getCacheDataService();
    if (cacheService === undefined) return;

    dispatch({
      type: SET_LOADING_PROJECTS,
      payload: {
        loading: true,
      },
    });

    const result = await cacheService.fetchProjects();

    dispatch({
      type: SET_LOADING_PROJECTS,
      payload: {
        loading: false,
      },
    });
    if (result.projects !== undefined) {
      const projects = result.projects;
      dispatch({
        type: SET_PROJECT_LIST,
        payload: {
          projects: projects,
        },
      });

      dispatch({
        type: RESET_PROJECT_WITH_LOADED_AREAS_COUNT,
      });

      const projectsIds = projects.map((projects) => projects.id);
      await loadProjectsAreaControllingParallelism(
        projectsIds,
        numberOfParallelProcessingProjects,
        dispatch
      );
    }
    if (result.error !== undefined) {
      dispatch(_dispatchError(result.error));
    }
  };
}

async function loadProjectsAreaControllingParallelism(
  projectsIds: string[],
  parallelismAmount: number,
  dispatch: Dispatch
): Promise<void> {
  let promises: Promise<Area[] | undefined>[] = [];
  let parallelProjectIds: string[] = [];
  let i = 0;

  let alreadyLoadedProjects: { projectId: string; areas: Area[] | undefined }[] = [];

  let dispatchAlreadyLoadedProjectsAfterTimeout: Promise<void> | undefined = undefined;

  const createDispatchAlreadyLoadedProjectsAfterTimeout = () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        if (alreadyLoadedProjects.length > 0) {
          dispatch({
            type: INCREASE_PROJECTS_WITH_LOADED_AREAS_COUNT,
            payload: {
              amount: alreadyLoadedProjects.filter((info) => info.areas !== undefined).length,
            },
          });

          dispatch({
            type: SET_PROJECTS_AREA_LIST,
            payload: {
              projects: alreadyLoadedProjects
                .filter((loadedProjects) => loadedProjects.areas !== undefined)
                .map((loadedProjects) => ({
                  projectId: loadedProjects.projectId,
                  areas: loadedProjects.areas!,
                })),
            },
          });

          dispatch({
            type: SET_PROJECTS_LOADING_AREAS,
            payload: {
              projectIds: alreadyLoadedProjects.map((info) => info.projectId),
              loading: false,
            },
          });

          alreadyLoadedProjects = [];
        }

        dispatchAlreadyLoadedProjectsAfterTimeout = undefined;
        resolve();
      }, 200);
    });

  while (i < projectsIds.length) {
    dispatchAlreadyLoadedProjectsAfterTimeout = undefined;

    while (promises.length < parallelismAmount && i < projectsIds.length) {
      const projectId = projectsIds[i];

      promises.push(
        new Promise(async (resolve) => {
          const result = await _dispatchLoadProjectAreas(dispatch, projectId, true);

          alreadyLoadedProjects.push({
            projectId: projectId,
            areas: result,
          });

          if (dispatchAlreadyLoadedProjectsAfterTimeout === undefined) {
            dispatchAlreadyLoadedProjectsAfterTimeout =
              createDispatchAlreadyLoadedProjectsAfterTimeout();
          }

          resolve(result);
        })
      );

      parallelProjectIds.push(projectId);
      i++;
    }

    dispatch({
      type: SET_PROJECTS_LOADING_AREAS,
      payload: {
        projectIds: parallelProjectIds,
        loading: true,
      },
    });

    await Promise.all(promises);

    if (dispatchAlreadyLoadedProjectsAfterTimeout !== undefined) {
      await (dispatchAlreadyLoadedProjectsAfterTimeout as Promise<void>);
    }

    promises = [];
    parallelProjectIds = [];
  }
}

async function _dispatchLoadProjectAreas(
  dispatch: Dispatch,
  projectId: string,
  moreThanOneProject?: true
): Promise<Area[] | undefined> {
  const cacheService = getCacheDataService();
  if (cacheService === undefined) return;

  if (moreThanOneProject === undefined) {
    dispatch({
      type: SET_PROJECT_LOADING_AREAS,
      payload: {
        projectId: projectId,
        loading: true,
      },
    });
  }

  const result = await cacheService.fetchAreas(projectId);

  if (moreThanOneProject === undefined) {
    dispatch({
      type: SET_PROJECT_LOADING_AREAS,
      payload: {
        projectId: projectId,
        loading: false,
      },
    });
  }

  if (result.error !== undefined) {
    dispatch(_dispatchError(result.error));
  }
  let areas = result.areas;

  if (moreThanOneProject === undefined) {
    if (areas !== undefined) {
      dispatch({
        type: SET_PROJECT_AREA_LIST,
        payload: {
          projectId: projectId,
          areas: areas,
        },
      });
    }
  }

  return areas;
}

function _dispatchError(error: BiohubError): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PROJECT_TREE_ERROR,
      payload: {
        error: error,
      },
    });
  };
}

export function setFlightVisible(projectId: string, areaId: string, flightId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_FLIGHT_VISIBILITY,
      payload: {
        projectId: projectId,
        areaId: areaId,
        flightId: flightId,
        visibility: true,
      },
    });
  };
}

export function setFlightInvisible(
  projectId: string,
  areaId: string,
  flightId: string
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_FLIGHT_VISIBILITY,
      payload: {
        projectId: projectId,
        areaId: areaId,
        flightId: flightId,
        visibility: false,
      },
    });
  };
}

export function setAreaVisible(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_AREA_VISIBILITY,
      payload: {
        projectId: projectId,
        areaId: areaId,
        visibility: true,
      },
    });
  };
}

export function setAreaInvisible(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_AREA_VISIBILITY,
      payload: {
        projectId: projectId,
        areaId: areaId,
        visibility: false,
      },
    });
  };
}

export function setProjectVisible(projectId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PROJECT_VISIBILITY,
      payload: {
        projectId: projectId,
        visibility: true,
      },
    });
  };
}

export function setProjectInvisible(projectId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PROJECT_VISIBILITY,
      payload: {
        projectId: projectId,
        visibility: false,
      },
    });
  };
}

/**
 * Select project logic:
 *
 * Select the project and if there isn't a selected project we call expandProject.
 */
export function selectProject(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    dispatch({
      type: SELECT_PROJECT,
      payload: {
        projectId: projectId,
      },
    });

    dispatch(fetchWeatherData());

    if (projectTreeState.selectedProjectId === null) {
      dispatch(expandProject(projectId));
    } else {
      const projectInProjectTree = projectTreeState.projectList?.find(
        (project) => project.id === projectId
      );
      if (projectInProjectTree === undefined) return;

      const projectAreas = projectInProjectTree.areas ?? [];
      if (projectAreas.length > 0) {
        dispatch(
          moveToBoundingBox(
            projectInProjectTree.boundingBox ??
              boundingBoxForAreas(projectAreas.map((area) => area.planned.polygon))!
          )
        );
      }
    }
  };
}

/**
 * Expand project logic:
 *
 * Expand the project, if we don't have a selected select the first area and load the areas
 * list if that list is null.
 */
export function expandProject(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;
    const projectInProjectTree = projectTreeState.projectList?.find(
      (project) => project.id === projectId
    );
    if (projectInProjectTree === undefined) return;

    dispatch({
      type: EXPAND_PROJECT,
      payload: {
        projectId: projectId,
      },
    });

    dispatch(loadProjectAreasAndSelectFirst(projectId));
  };
}

/**
 * Collapse project logic:
 *
 * Collapse: Collapse that project.
 */
export function collapseProject(projectId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: COLLAPSE_PROJECT,
      payload: {
        projectId: projectId,
      },
    });
  };
}

/**
 * Close project logic:
 *
 * Collapse that project, if there is a selected area we call closeArea, every area
 * of that project must be marked as not visible and not expanded and we need to remove the
 * state selected project id value.
 */
export function closeProject(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    dispatch(collapseProject(projectId));

    dispatch({
      type: SELECT_PROJECT,
      payload: {
        projectId: null,
      },
    });

    const stateProject = projectTreeState.projectList?.find((project) => project.id === projectId);
    if (stateProject === undefined) return;

    const selectedAreId = stateProject.selectedAreaId;
    if (selectedAreId !== null) {
      dispatch(closeArea(projectId, selectedAreId));
    }

    dispatch(setProjectInvisible(projectId));
  };
}

/**
 * Select area logic:
 *
 * Select the project that have that area.
 * If that area doesn't have a planned path we call the mission planner.
 */
export function selectArea(
  projectId: string,
  areaId: string,
  notGoToBoundingBox?: true
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    if (projectTreeState.selectedProjectId !== projectId) {
      dispatch({
        type: SELECT_PROJECT,
        payload: {
          projectId: projectId,
        },
      });
    }

    dispatch({
      type: SELECT_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
      },
    });

    const areaInProjectTree = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (areaInProjectTree === undefined) return;

    if (notGoToBoundingBox === undefined)
      dispatch(moveToBoundingBox(areaInProjectTree.boundingBox));

    dispatch(fetchWeatherData());

    const profileState = getState().profile;
    const mustReleaseEntireArea = getMustReleaseEntireArea(profileState);

    if (areaInProjectTree.planned.route === undefined) {
      let homePoint: Location | undefined;
      if (
        areaInProjectTree.areaConfig.useHighestPolygonPointElevationDataAsHomePoint &&
        areaInProjectTree.polygonElevationData !== null
      ) {
        const highestElevationPoint = areaInProjectTree.polygonElevationData.reduce((a, b) => {
          if (a.elevation > b.elevation) {
            return a;
          }
          return b;
        });

        homePoint = {
          lat: highestElevationPoint.lat,
          lng: highestElevationPoint.lng,
        };
      }

      dispatch(
        runMissionPlanner({
          projectId: projectId,
          areaId: areaId,
          areaPolygon: areaInProjectTree.planned.polygon,
          areaConfig: areaInProjectTree.areaConfig,
          homePoint: homePoint,
          configuredReleasers: areaInProjectTree.configuredReleasers,
          mustReleaseEntireArea: mustReleaseEntireArea,
        })
      );
    }
  };
}

export function moveToBoundingBox(boundingBox: BoundingBox): SystemThunk {
  return async (dispatch) => {
    if (baseMapController === null) return;

    dispatch({
      type: SET_MOVING_MAP_CAMERA,
      payload: { moving: true },
    });

    await baseMapController.moveToBoundingBox(boundingBox);

    dispatch({
      type: SET_MOVING_MAP_CAMERA,
      payload: { moving: false },
    });
  };
}

/**
 * Expand area logic:
 *
 * Expand the area, if the flight don't have flights we try to load the area flights.
 */
export function expandArea(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    dispatch({
      type: EXPAND_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
      },
    });

    const areaFlights = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId)?.flightList;

    if (areaFlights === undefined || areaFlights === null || areaFlights.length === 0) {
      dispatch(loadAreaFlights(projectId, areaId));
    }
  };
}

/**
 * Collapse area logic:
 *
 * Collapse: Collapse that area.
 */
export function collapseArea(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: COLLAPSE_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
      },
    });
  };
}

/**
 * Close area logic:
 *
 * Collapse that area, if we have visible flights mark every flight as invisible,
 * mark that area as invisible and remove the selected area id from state.
 */
export function closeArea(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const stateProject = projectTreeState.projectList?.find((project) => project.id === projectId);
    if (stateProject === undefined) return;

    const stateArea = stateProject.areas?.find((area) => area.id === areaId);
    if (stateArea === undefined) return;

    dispatch(collapseArea(projectId, areaId));

    const selectedAreaId = stateProject.selectedAreaId;
    if (selectedAreaId === areaId) {
      dispatch({
        type: SELECT_AREA,
        payload: {
          projectId: projectId,
          areaId: null,
        },
      });
    }

    dispatch(fetchWeatherData());

    const areaFlights = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId)?.flightList;
    if (areaFlights === undefined || areaFlights === null) return;

    dispatch(setAreaInvisible(projectId, areaId));
  };
}

export function loadProjectAreasAndSelectFirst(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    const loadedAreas = await _dispatchLoadProjectAreas(dispatch, projectId);
    if (loadedAreas !== undefined) {
      const projectTreeState = getState().projectTree;
      const projectInProjectTree = projectTreeState.projectList?.find(
        (project) => project.id === projectId
      );

      if (projectInProjectTree === undefined) return;

      dispatch(
        moveToBoundingBox(
          projectInProjectTree.boundingBox ??
            boundingBoxForAreas(loadedAreas.map((area) => area.planned.polygon))!
        )
      );

      if (projectInProjectTree.selectedAreaId === null && loadedAreas.length > 0) {
        dispatch(selectArea(projectId, loadedAreas[0].id, true));
      }
    }
  };
}

export function loadAreaFlights(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    const cacheService = getCacheDataService();
    if (cacheService === undefined) return;

    dispatch({
      type: SET_AREA_LOADING_FLIGHTS,
      payload: {
        projectId: projectId,
        areaId: areaId,
        loading: true,
      },
    });

    const result = await cacheService.fetchAreaFlights(projectId, areaId);

    dispatch({
      type: SET_AREA_LOADING_FLIGHTS,
      payload: {
        projectId: projectId,
        areaId: areaId,
        loading: false,
      },
    });

    const flights = result.flights;

    if (flights !== undefined) {
      dispatch({
        type: SET_AREA_FLIGHT_LIST,
        payload: {
          projectId: projectId,
          areaId: areaId,
          flights: flights,
        },
      });
    }
    if (result.error !== undefined) {
      dispatch(_dispatchError(result.error));
    }
  };
}

// actions cpuLog
export function loadCpuLogs(directClientId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_CPU_LOGS,
      payload: {
        loading: true,
      },
    });

    const result = await CpuLogService.readCpuLog(directClientId);

    dispatch({
      type: SET_LOADING_CPU_LOGS,
      payload: {
        loading: false,
      },
    });

    if (result.success) {
      // Converta o objeto de CpuLog em um array antes de passá-lo para o reducer
      const updatedCpuLogs = Array.isArray(result.data) ? result.data : [result.data];

      dispatch({
        type: SET_CPU_LOGS_LIST,
        payload: {
          cpuLogs: updatedCpuLogs,
        },
      });
    } else {
      dispatch(_dispatchError(result.error));
    }
  };
}

export function expandCpuLogs(): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: EXPAND_CPUS_LOGS,
    });

    const state = getState();
    const directClientId = state.profile.userProfile?.directClientId;

    if (directClientId) {
      dispatch(loadCpuLogs(directClientId));
    }
  };
}

export function collapseCpuLogs(): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: COLLAPSE_CPU_LOGS,
    });
  };
}

export type AreaConfigWithoutId = Omit<AreaConfig, "id">;

export type CreteProjectInfo = {
  directClientId: string;
  indirectClientId: string | null;
  projectName: string;
  areaConfig: AreaConfigWithoutId;
  configuredReleasers: ReleaserConfiguration[];
};

export function createProject(
  info: CreteProjectInfo & { areas: AreaCreationParameters[] }
): SystemThunk {
  return async (dispatch, getState) => {
    const projectId = uuid();
    dispatch({
      type: SET_CREATING_PROJECT,
      payload: {
        creating: true,
        projectId: projectId,
      },
    });

    const {
      projectName,
      areaConfig,
      areas,
      configuredReleasers,
      directClientId,
      indirectClientId,
    } = info;

    const projectTreeState = getState().projectTree;

    const project: Project = {
      id: projectId,
      configuredReleasers: configuredReleasers,
      createdAt: new Date(),
      name: determineNewProjectName(projectName, projectTreeState.projectList ?? []),
      directClientId: directClientId,
      indirectClientId: indirectClientId,
      areaConfig: {
        ...areaConfig,
        id: uuid(),
      },
    };

    const result = await ProjectService.addProject(project);
    if (!result.success) {
      dispatch(_dispatchError(result.error));
    } else {
      dispatch({
        type: PUT_PROJECT,
        payload: {
          project: {
            ...project,
            areaCount: areas.length,
          },
        },
      });

      dispatch(createAreas(project.id, areas));
    }

    dispatch({
      type: SET_CREATING_PROJECT,
      payload: {
        creating: false,
        projectId: projectId,
      },
    });

    dispatch(loadProjects());
  };
}

export function createAreas(projectId: string, areas: AreaCreationParameters[]): SystemThunk {
  return async (dispatch) => {
    const promises = areas.map(
      (areaInfo) =>
        new Promise<void>((resolve, _) => {
          const createAreaAction = createAreaCreationAction(
            projectId,
            areaInfo,
            async () => {},
            async () => {
              resolve();
            }
          );
          dispatch(createAreaAction());
        })
    );

    await Promise.all(promises);

    await _dispatchLoadProjectAreas(dispatch, projectId);
  };
}

function determineNewProjectName(projectName: string, projectList: ProjectInProjectTree[]): string {
  const hasProjectWithThatName =
    projectList.filter((project) => project.name === projectName).length > 0;

  if (!hasProjectWithThatName) return projectName;

  let increment = 1;
  while (true) {
    const nameCandidate = `${projectName} (${increment})`;

    const hasProjectWithThatName =
      projectList.filter((project) => project.name === nameCandidate).length > 0;
    if (!hasProjectWithThatName) return nameCandidate;
    increment++;
  }
}

export type AreaCreationParameters = {
  areaName: string;
  areaPolygon: Location[];
  areaConfig: AreaConfigWithoutId;
  configuredReleasers: ReleaserConfiguration[];
};

export function createArea(projectId: string, info: AreaCreationParameters): SystemThunk {
  return createAreaCreationAction(
    projectId,
    info,
    async (area, dispatch) => {
      dispatch({
        type: PUT_PROJECT_AREA,
        payload: {
          projectId: projectId,
          area: area,
        },
      });
    },
    async (dispatch) => {
      await _dispatchLoadProjectAreas(dispatch, projectId);
    }
  )();
}

export function createAreaDuplicate(originalArea: Area, intl: IntlShape): SystemThunk {
  return async (dispatch) => {
    dispatch(_createAreaDuplicateInAProject(originalArea, originalArea.projectId, intl));
  };
}

function _createAreaDuplicateInAProject(
  originalArea: Area,
  projectId: string,
  intl?: IntlShape
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === projectId);
    if (project === undefined) return;

    const areaId = uuid();
    dispatch({
      type: SET_CREATING_AREA,
      payload: {
        projectId: projectId,
        creating: true,
        areaId: areaId,
      },
    });

    const area: Area = {
      ...originalArea,
      id: areaId,
      name:
        originalArea.projectId === projectId
          ? `${originalArea.name} - ${intl?.formatMessage({ id: "generic.copy" }) ?? "Copy"}`
          : originalArea.name,
      areaConfig: {
        ...originalArea.areaConfig,
        id: uuid(),
      },
      projectId: projectId,
      createdAt: new Date(),
      deletedAt: undefined,
      lastPartialMissionId: undefined,
      lastWaypointFlown: undefined,
      planned: {
        ...originalArea.planned,
        generatedAt: new Date(),
        route:
          originalArea.planned.route !== undefined
            ? {
                ...originalArea.planned.route,
                generatedAt: new Date(),
              }
            : undefined,
      },
    };

    const result = await ProjectService.addArea(area);
    dispatch({
      type: SET_CREATING_AREA,
      payload: {
        projectId: projectId,
        creating: false,
        areaId: areaId,
      },
    });

    if (!result.success) {
      dispatch(_dispatchError(result.error));
    } else {
      dispatch({
        type: PUT_PROJECT_AREA,
        payload: {
          projectId: projectId,
          area: area,
        },
      });
    }

    await _dispatchLoadProjectAreas(dispatch, projectId);
  };
}

const createAreaCreationAction = (
  projectId: string,
  info: AreaCreationParameters,
  afterSuccessSaving: (
    area: Area,
    dispath: Dispatch,
    getState: () => SystemCombinedState
  ) => Promise<void>,
  afterAll: (dispath: Dispatch, getState: () => SystemCombinedState) => Promise<void>
): (() => SystemThunk) => {
  const action: () => SystemThunk = () => async (dispatch, getState) => {
    const { areaName, areaPolygon, areaConfig, configuredReleasers } = info;

    const areaHa = calculateAreaHa(areaPolygon);
    if (areaHa > 10000) {
      dispatch(
        _dispatchError({
          errorCode: BiohubErrorCode.TO_BIG_AREA,
        })
      );
    } else {
      const areaId = uuid();
      dispatch({
        type: SET_CREATING_AREA,
        payload: {
          projectId: projectId,
          creating: true,
          areaId: areaId,
        },
      });

      const now = new Date();
      const area: Area = {
        id: areaId,
        name: determineNewAreaName(
          areaName,
          getState().projectTree.projectList?.find((project) => project.id === projectId)?.areas ??
            []
        ),
        planned: {
          id: uuid(),
          generatedAt: now,
          polygon: areaPolygon,
          basePoint: null,
          routeAngle: null,
        },
        projectId: projectId,
        createdAt: now,
        areaConfig: {
          ...areaConfig,
          id: uuid(),
        },
        configuredReleasers: configuredReleasers,
        polygonElevationData: null,
      };

      const result = await ProjectService.addArea(area);
      dispatch({
        type: SET_CREATING_AREA,
        payload: {
          projectId: projectId,
          creating: false,
          areaId: areaId,
        },
      });

      if (!result.success) {
        dispatch(_dispatchError(result.error));
      } else {
        await afterSuccessSaving(area, dispatch, getState);
      }
    }

    await afterAll(dispatch, getState);
  };

  return action;
};

function determineNewAreaName(areaName: string, areaList: AreaInProjectTree[]): string {
  const hasAreaWithThatName = areaList.filter((area) => area.name === areaName).length > 0;

  if (!hasAreaWithThatName) return areaName;

  let increment = 1;
  while (true) {
    const nameCandidate = `${areaName} (${increment})`;

    const hasAreaWithThatName = areaList.filter((area) => area.name === nameCandidate).length > 0;
    if (!hasAreaWithThatName) return nameCandidate;
    increment++;
  }
}

function areaInProjectTreeToArea(area: AreaInProjectTree): Area {
  return {
    id: area.id,
    areaConfig: area.areaConfig,
    configuredReleasers: area.configuredReleasers,
    createdAt: area.createdAt,
    name: area.name,
    planned: area.planned,
    projectId: area.projectId,
    deletedAt: area.deletedAt === null ? undefined : area.deletedAt,
    polygonElevationData: area.polygonElevationData,
  };
}

export function updateProject(project: Partial<Project> & { id: string }): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_UPDATING_PROJECT,
      payload: {
        updating: true,
        projectId: project.id,
      },
    });

    const result = await ProjectService.updateProject(project);
    dispatch({
      type: SET_UPDATING_PROJECT,
      payload: {
        updating: false,
        projectId: project.id,
      },
    });
    if (!result.success) {
      dispatch(_dispatchError(result.error));
    } else {
      dispatch({
        type: UPDATE_PROJECT,
        payload: {
          project: project,
        },
      });
    }

    dispatch(loadProjects());
  };
}

export function updateArea(area: Partial<Area> & { id: string; projectId: string }): SystemThunk {
  return async (dispatch, getState) => {
    const projectId = area.projectId;
    const areaId = area.id;

    dispatch({
      type: SET_UPDATING_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
        updating: true,
      },
    });

    const result = await ProjectService.updateArea(area);

    dispatch({
      type: SET_UPDATING_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
        updating: false,
      },
    });

    if (!result.success) {
      dispatch(_dispatchError(result.error));
    } else {
      dispatch({
        type: UPDATE_PROJECT_AREA,
        payload: {
          projectId: projectId,
          area: area,
        },
      });
    }

    await _dispatchLoadProjectAreas(dispatch, projectId);
  };
}

export function runMissionPlanner(info: {
  projectId: string;
  areaId: string;
  areaPolygon: Location[];
  areaConfig: AreaConfig;
  homePoint: Location | undefined;
  configuredReleasers: ReleaserConfiguration[];
  mustReleaseEntireArea: boolean;
}): SystemThunk {
  return async (dispatch, getState) => {
    const {
      projectId,
      areaId,
      areaConfig,
      areaPolygon,
      homePoint,
      configuredReleasers,
      mustReleaseEntireArea,
    } = info;

    const projectTreeState = getState().projectTree;

    const stateArea = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (stateArea === undefined) return;

    dispatch({
      type: SET_RUNNING_MISSION_PLANNER,
      payload: {
        projectId: projectId,
        areaId: areaId,
        planning: true,
      },
    });

    const plannedPath = executeMissionPlanner(
      areaPolygon,
      homePoint,
      areaConfig,
      stateArea.planned,
      mustReleaseEntireArea,
      configuredReleasers,
      stateArea.polygonElevationData
    );

    if (
      plannedPath.id !== stateArea.planned.id ||
      plannedPath.route?.id !== stateArea.planned.route?.id
    ) {
      /// We need to update the area
      dispatch(
        updateArea({
          id: areaId,
          projectId: projectId,
          planned: plannedPath,
        })
      );
    }

    dispatch({
      type: SET_RUNNING_MISSION_PLANNER,
      payload: {
        projectId: projectId,
        areaId: areaId,
        planning: false,
      },
    });
  };
}

export function processImportedRoute(info: {
  projectId: string;
  areaId: string;
  route: ImportedRouteData;
  areaConfig: AreaConfig;
  homePoint: Location | undefined;
  configuredReleasers: ReleaserConfiguration[];
  mustReleaseEntireArea: boolean;
}): SystemThunk {
  return async (dispatch, getState) => {
    const {
      projectId,
      areaId,
      areaConfig,
      route,
      homePoint,
      configuredReleasers,
      mustReleaseEntireArea,
    } = info;

    const projectTreeState = getState().projectTree;

    const stateArea = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (stateArea === undefined) return;

    // Here we must check if the imported route is inside the area
    const areaBoundingBox = boundingBoxForAreas([stateArea.planned.polygon]);
    if (!areaBoundingBox) {
      dispatch(
        _dispatchError({
          errorCode: BiohubErrorCode.KML_IMPORT_INVALID_SELECTED_AREA_BOUNDS,
        })
      );
      return;
    }
    const isKmlInsideArea = isPointsInsideBoundingBox(route, areaBoundingBox);
    if (!isKmlInsideArea) {
      dispatch(
        _dispatchError({
          errorCode: BiohubErrorCode.KML_ROUTE_OUT_OF_AREA_BOUNDS,
        })
      );
      return;
    }

    dispatch({
      type: SET_RUNNING_MISSION_PLANNER,
      payload: {
        projectId: projectId,
        areaId: areaId,
        planning: true,
      },
    });

    let elevationData: number[] | undefined;

    if (route.map((point) => point.alt).filter((altitude) => altitude === undefined).length === 0) {
      const elevationDataResult = await ElevationService.getElevationsForPath(route);
      if (elevationDataResult.success) {
        elevationData = elevationDataResult.data;
      }
    }

    let waypoints: Waypoint[] = route.map((point, index) => {
      let waypointHeight: number = areaConfig.flightHeight;
      let waypointElevation = 0;

      if (elevationData !== undefined) {
        waypointHeight = point.alt! - elevationData[index];
        waypointElevation = elevationData[index];
      }

      return {
        location: {
          lat: point.lat,
          lng: point.lng,
        },
        curveRadius: 0.2,
        droneActions: [],
        height: waypointHeight,
        orientation: 0,
        releaserActions: {},
        speed: areaConfig.flightSpeed,
        elevation: waypointElevation,
      };
    });

    const newHomePoint =
      homePoint !== undefined
        ? homePoint
        : chooseDefaultHomePoint(stateArea.planned.polygon, waypoints);

    const headingMode = areaConfig.headingMode;
    if (headingMode === HeadingMode.nextWaypoint) {
      waypoints = fixHeadingForWaypoints(waypoints, newHomePoint);
    }

    const curveMode = areaConfig.curveMode;
    if (curveMode === CurveMode.curved) {
      waypoints = calculateCurveOfAllWaypoints(waypoints, areaConfig.defaultCurvePercentage);
    }

    if (mustReleaseEntireArea) {
      const initialReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      const finalReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      configuredReleasers.forEach((configuredReleaser) => {
        initialReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.release,
          params: [],
        };

        finalReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.stopRelease,
          params: [],
        };
      });

      waypoints = waypoints.map((waypoint, waypointIndex) => ({
        ...waypoint,
        releaserActions:
          waypointIndex === 0
            ? initialReleasersActions
            : waypointIndex === waypoints.length - 1
            ? finalReleasersActions
            : waypoint.releaserActions,
      }));
    }

    const plannedPath = plannedRouteControlUpdate(stateArea.planned, {
      homePoint: newHomePoint,
      waypoints: waypoints,
      basePoint: null,
      routeAngle: null,
    });

    if (
      plannedPath.id !== stateArea.planned.id ||
      plannedPath.route?.id !== stateArea.planned.route?.id
    ) {
      dispatch(
        updateArea({
          id: areaId,
          projectId: projectId,
          planned: plannedPath,
        })
      );
    }

    dispatch({
      type: SET_RUNNING_MISSION_PLANNER,
      payload: {
        projectId: projectId,
        areaId: areaId,
        planning: false,
      },
    });
  };
}

export function manuallyUpdateAreaPlan(info: {
  projectId: string;
  areaId: string;
  polygon?: Location[];
  homePoint?: Location;
  waypoints?: Waypoint[];
}): SystemThunk {
  return async (dispatch, getState) => {
    const { projectId, areaId, polygon, homePoint, waypoints } = info;

    const projectTreeState = getState().projectTree;

    const stateArea = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (stateArea === undefined) return;

    if (polygon === undefined && homePoint === undefined && waypoints === undefined) return;

    dispatch({
      type: SET_UPDATING_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
        updating: true,
      },
    });

    let newPlannedArea = _.cloneDeep(stateArea.planned);
    if (polygon !== undefined && !_.isEqual(stateArea.planned.polygon, polygon)) {
      newPlannedArea = {
        ...newPlannedArea,
        polygon: polygon,
        id: uuid(),
        generatedAt: new Date(),
      };
    }

    if (homePoint !== undefined && !_.isEqual(homePoint, stateArea.planned.homePoint)) {
      newPlannedArea = {
        ...newPlannedArea,
        homePoint: homePoint,
        id: uuid(),
        generatedAt: new Date(),
      };
    }

    if (waypoints !== undefined && !_.isEqual(waypoints, stateArea.planned.route?.waypoints)) {
      newPlannedArea = {
        ...newPlannedArea,
        id: uuid(),
        generatedAt: new Date(),
        route: {
          id: uuid(),
          ...stateArea.planned.route,
          generatedAt: new Date(),
          waypoints: waypoints,
        },
      };
    }

    if (
      stateArea.planned.id !== newPlannedArea.id ||
      stateArea.planned.route?.id !== newPlannedArea.route?.id
    ) {
      /// We need to update the area
      dispatch(
        updateArea({
          id: areaId,
          projectId: projectId,
          planned: newPlannedArea,
        })
      );
    }

    dispatch({
      type: SET_UPDATING_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
        updating: false,
      },
    });
  };
}

export function setUserLocation(location: Location | null): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_LOCATION,
      payload: {
        userLocation: location,
      },
    });

    dispatch(fetchWeatherData());
  };
}

export function deleteProject(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    dispatch(
      updateProject({
        id: projectId,
        deletedAt: new Date(),
      })
    );
  };
}

export function restoreProject(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    dispatch(
      updateProject({
        id: projectId,
        deletedAt: undefined,
      })
    );
  };
}

export function deleteArea(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch(
      updateArea({
        projectId: projectId,
        id: areaId,
        deletedAt: new Date(),
      })
    );
  };
}

export function restoreArea(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch(
      updateArea({
        projectId: projectId,
        id: areaId,
        deletedAt: undefined,
      })
    );
  };
}

export function selectToViewFlightPlan(
  projectId: string,
  areaId: string,
  plannedAreaId: string
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const flight = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId)
      ?.flightList?.find(
        (flight) => flight.flightEnvironmentSnapshot.plannedArea.id === plannedAreaId
      );
    if (flight === undefined) return;

    dispatch({
      type: SELECT_VIEW_FLIGHT_PLAN,
      payload: {
        projectId: projectId,
        areaId: areaId,
        plan: flight.flightEnvironmentSnapshot.plannedArea,
      },
    });
  };
}

export function quitViewFlightPlan(projectId: string, areaId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: QUIT_VIEW_FLIGHT_PLAN,
      payload: {
        projectId: projectId,
        areaId: areaId,
      },
    });
  };
}

export function createProjectDrawingArea(
  projectInfo: CreteProjectInfo,
  areaName: string
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "create-project",
        projectInfo: projectInfo,
        areaName: areaName,
      },
    });
  };
}

export function closeEditionMode(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: QUIT_EDIT_AREA_MODE,
    });
  };
}

export function createAreaDrawingArea(
  projectId: string,
  areaInfo: {
    areaName: string;
    areaConfig: AreaConfigWithoutId;
    configuredReleasers: ReleaserConfiguration[];
  }
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "create-area",
        projectId: projectId,
        areaName: areaInfo.areaName,
        areaConfig: areaInfo.areaConfig,
        configuredReleasers: areaInfo.configuredReleasers,
      },
    });
  };
}

export function editAreaParameters(
  projectId: string,
  areaId: string,
  areaName: string,
  areaConfig: AreaConfigWithoutId,
  configuredReleasers: ReleaserConfiguration[],
  mustReleaseEntireArea: boolean,
  unlockedToExecuteMissionPlanner: boolean
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    let area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    dispatch({
      type: SET_UPDATING_AREA,
      payload: {
        projectId: projectId,
        areaId: areaId,
        updating: true,
      },
    });

    let missionPlannerChanges: boolean = false;

    const previousAreaConfig = area.areaConfig;
    if (
      areaConfig.areaPadding !== previousAreaConfig.areaPadding ||
      areaConfig.curveMode !== previousAreaConfig.curveMode ||
      areaConfig.defaultCurvePercentage !== previousAreaConfig.defaultCurvePercentage ||
      areaConfig.flightHeight !== previousAreaConfig.flightHeight ||
      areaConfig.flightSpeed !== previousAreaConfig.flightSpeed ||
      areaConfig.headingMode !== previousAreaConfig.headingMode ||
      areaConfig.mustConsiderRelief !== previousAreaConfig.mustConsiderRelief ||
      areaConfig.trackWidth !== previousAreaConfig.trackWidth ||
      areaConfig.useHighestPolygonPointElevationDataAsHomePoint !==
        previousAreaConfig.useHighestPolygonPointElevationDataAsHomePoint ||
      areaConfig.canUseOutsidePolygonSegmentsInRoute !==
        previousAreaConfig.canUseOutsidePolygonSegmentsInRoute
    ) {
      missionPlannerChanges = true;
    } else if (area.planned.route === undefined) {
      missionPlannerChanges = true;
    } else if (!_.isEqual(area.configuredReleasers, configuredReleasers)) {
      missionPlannerChanges = true;
    } else if (mustReleaseEntireArea) {
      const plannedWaypoints = area.planned.route.waypoints;
      const firstWaypointReleasersActions = plannedWaypoints[0].releaserActions;
      const lastWaypointReleasersActions =
        plannedWaypoints[plannedWaypoints.length - 1].releaserActions;

      const initialReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      const finalReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      configuredReleasers.forEach((configuredReleaser) => {
        initialReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.release,
          params: [],
        };

        finalReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.stopRelease,
          params: [],
        };
      });

      if (
        !_.isEqual(firstWaypointReleasersActions, initialReleasersActions) ||
        !_.isEqual(lastWaypointReleasersActions, finalReleasersActions)
      ) {
        missionPlannerChanges = true;
      }
    }

    area = {
      ...area,
      name: areaName,
      areaConfig: {
        ...area.areaConfig,
        ...areaConfig,
      },
      configuredReleasers: configuredReleasers,
    };

    if (unlockedToExecuteMissionPlanner && missionPlannerChanges) {
      dispatch({
        type: SET_RUNNING_MISSION_PLANNER,
        payload: {
          projectId: projectId,
          areaId: areaId,
          planning: true,
        },
      });

      const areaPlan = executeMissionPlanner(
        area.planned.polygon,
        area.planned.homePoint,
        area.areaConfig,
        area.planned,
        mustReleaseEntireArea,
        configuredReleasers,
        area.polygonElevationData
      );

      area = {
        ...area,
        planned: areaPlan,
      };

      dispatch({
        type: SET_RUNNING_MISSION_PLANNER,
        payload: {
          projectId: projectId,
          areaId: areaId,
          planning: false,
        },
      });
    }

    dispatch(updateArea(areaInProjectTreeToArea(area)));
  };
}

export function zoomIn(): SystemThunk {
  return async () => {
    if (baseMapController === null) return;

    await baseMapController.zoomIn();
    /**
     * Its not necessary emit that zoom change calling another dispatch because the map
     * component will do the onZoomChanged call and that will dispatch those changes
     */
  };
}

export function setZoom(percentage: number): SystemThunk {
  return async () => {
    if (baseMapController === null) return;

    await baseMapController.setZoom(
      mapMinimumZoom + ((mapMaximumZoom - mapMinimumZoom) * percentage) / 100
    );
    /**
     * Its not necessary emit that zoom change calling another dispatch because the map
     * component will do the onZoomChanged call and that will dispatch those changes
     */
  };
}

export function zoomOut(): SystemThunk {
  return async () => {
    if (baseMapController === null) return;

    await baseMapController.zoomOut();
    /**
     * Its not necessary emit that zoom change calling another dispatch because the map
     * component will do the onZoomChanged call and that will dispatch those changes
     */
  };
}

export function moveToCoordinates(coordinate: Location, zoomLevel?: number): SystemThunk {
  return async (dispatch, getState) => {
    if (baseMapController === null) return;

    if (getState().projectTree.movingCamera) return;

    dispatch({
      type: SET_MOVING_MAP_CAMERA,
      payload: { moving: true },
    });

    await baseMapController.moveTo(coordinate, zoomLevel);
    /**
     * Its not necessary emit that change calling another dispatch because the map
     * component will do the onCenterChanged call and that will dispatch those changes
     */

    dispatch({
      type: SET_MOVING_MAP_CAMERA,
      payload: { moving: false },
    });
  };
}

export function changeMapType(mapType: BaseMapTypeId): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_MAP_TYPE,
      payload: {
        mapType: mapType,
      },
    });
  };
}

export function enterEditAreaPointsMode(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "edit-points",
        area: areaInProjectTreeToArea(area),
      },
    });
  };
}

export function enterEditAreaPolygonMode(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "edit-polygon",
        area: areaInProjectTreeToArea(area),
      },
    });
  };
}

export function enterEditAreaRouteMode(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "edit-route",
        area: areaInProjectTreeToArea(area),
      },
    });
  };
}

export function enterEditAreaManualRouteMode(projectId: string, areaId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "manual-route",
        area: areaInProjectTreeToArea(area),
      },
    });
  };
}

export function onMapZoomChanged(): SystemThunk {
  return async (dispatch) => {
    if (baseMapController === null) return;

    const mapZoom = await baseMapController.getZoom();

    const mapBounds = await baseMapController.getMapBounds();
    const mapCenter = mapBounds !== undefined ? centerOfBoundingBox(mapBounds) : undefined;
    dispatch({
      type: SET_MAP_STATE,
      payload: {
        center: mapCenter,
        zoom: mapZoom,
        boundingBoxDiagonalSize:
          mapBounds !== undefined ? boundingBoxDiagonalSize(mapBounds) : undefined,
      },
    });
    if (mapBounds === undefined) return;

    dispatch(onMapBoundsChanged(mapBounds));
  };
}

function delay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function onMapCenterChanged(mapCenter: Location): SystemThunk {
  return async (dispatch, getState) => {
    const actualCenter = getState().projectTree.mapState.center;

    if (JSON.stringify(actualCenter) === JSON.stringify(mapCenter)) return;

    dispatch(fetchMapBounds());
  };
}

export function onMapBoundsChanged(mapBounds: BoundingBox): SystemThunk {
  return async (dispatch, getState) => {
    const mapState = getState().projectTree.mapState;
    const actualBounds = mapState.bounds;

    if (JSON.stringify(mapBounds) === JSON.stringify(actualBounds)) return;

    dispatch({
      type: SET_MAP_STATE,
      payload: {
        bounds: mapBounds,
        boundingBoxCenter: centerOfBoundingBox(mapBounds),
      },
    });

    if (baseMapController === null) return;

    const actualMapCenter = centerOfBoundingBox(mapBounds);

    const outsideNorthLimit = mapBounds.north - 85;
    const outsideSouthLimit = -85 - mapBounds.south;

    if (outsideNorthLimit > 0) {
      dispatch(
        moveToCoordinates({
          lat: actualMapCenter.lat - outsideNorthLimit,
          lng: actualMapCenter.lng,
        })
      );
    } else if (outsideSouthLimit > 0) {
      dispatch(
        moveToCoordinates({
          lat: actualMapCenter.lat + outsideSouthLimit,
          lng: actualMapCenter.lng,
        })
      );
    }
  };
}

function fetchMapBounds(): SystemThunk {
  return async (dispatch) => {
    if (baseMapController === null) return;

    const mapBounds = await baseMapController.getMapBounds();
    if (mapBounds === undefined) return;

    const center = centerOfBoundingBox(mapBounds);

    dispatch({
      type: SET_MAP_STATE,
      payload: {
        bounds: mapBounds,
        boundingBoxCenter: center,
        center: center,
        boundingBoxDiagonalSize: boundingBoxDiagonalSize(mapBounds),
      },
    });
  };
}

export function notifyStartDraggingMaker(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_MAP_STATE,
      payload: {
        draggingMarker: true,
      },
    });
  };
}

export function notifyFinishDraggingMaker(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_MAP_STATE,
      payload: {
        draggingMarker: false,
      },
    });
  };
}

export function onEditingAreaVertexChanged(index: number, location: Location): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (
      newEditingArea.type === EditingAreaType.CreatingProject ||
      newEditingArea.type === EditingAreaType.DrawingNewArea ||
      newEditingArea.type === EditingAreaType.EditingPolygon ||
      newEditingArea.type === EditingAreaType.EditingEverything
    ) {
      newEditingArea.polygon[index] = location;

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function onEditingAreaWaypointLocationChanged(
  index: number,
  location: Location
): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (
      newEditingArea.type === EditingAreaType.EditingPlanPoints ||
      newEditingArea.type === EditingAreaType.EditingEverything
    ) {
      newEditingArea.waypoints[index].location = location;

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function onEditingClickPlannedPathLine(
  previousPointIndex: number,
  location: Location
): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (
      newEditingArea.type === EditingAreaType.EditingPlanPoints ||
      newEditingArea.type === EditingAreaType.EditingEverything
    ) {
      const newWaypoint = getNewWaypoint(location, newEditingArea.area.areaConfig);

      newEditingArea.waypoints.splice(previousPointIndex + 1, 0, newWaypoint);

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function onHomePointEditingAreaChanged(location: Location): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (
      newEditingArea.type === EditingAreaType.EditingPlanPoints ||
      newEditingArea.type === EditingAreaType.EditingEverything
    ) {
      newEditingArea.homePoint = location;

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function updateEditingArea(editingArea: EditingArea): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    if (projectTreeState.editingArea === undefined) return;

    let _editingArea = { ...editingArea };
    const editionType = _editingArea.type;

    const mustReleaseEntireArea = getMustReleaseEntireArea(getState().profile);

    if (
      mustReleaseEntireArea &&
      (editionType === EditingAreaType.EditingPlanPoints ||
        editionType === EditingAreaType.EditingEverything)
    ) {
      const cast = _editingArea as EditingArea & {
        type: EditingAreaType.EditingPlanPoints | EditingAreaType.EditingEverything;
      };
      const configuredReleasers = cast.area.configuredReleasers;

      const initialReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      const finalReleasersActions: {
        [releaserId: string]: {
          type: ReleaserAction;
          params: any[];
        };
      } = {};
      configuredReleasers.forEach((configuredReleaser) => {
        initialReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.release,
          params: [],
        };

        finalReleasersActions[configuredReleaser.releaserId] = {
          type: ReleaserAction.stopRelease,
          params: [],
        };
      });

      const waypoints = cast.waypoints;

      _editingArea = {
        ...cast,
        waypoints: waypoints.map((waypoint, waypointIndex) => ({
          ...waypoint,
          releaserActions:
            waypointIndex === 0
              ? initialReleasersActions
              : waypointIndex === waypoints.length - 1
              ? finalReleasersActions
              : {},
        })),
      };
    }

    dispatch({
      type: UPDATE_EDIT_AREA_DATA,
      payload: {
        editingArea: _editingArea,
      },
    });
  };
}

function getMustReleaseEntireArea(profileState: ProfileState): boolean {
  let mustReleaseEntireArea = true;
  const userProfile = profileState.userProfile;
  if (userProfile !== null && userProfile.role !== Role.external) {
    mustReleaseEntireArea = userProfile.preferences.mustReleaseEntireArea;
  }

  return mustReleaseEntireArea;
}

export function putFlightPlanInAreaPlan(
  projectId: string,
  areaId: string,
  flightPlan: CompletePlannedArea
): SystemThunk {
  return async (dispatch) => {
    dispatch(
      updateArea({
        id: areaId,
        projectId: projectId,
        planned: flightPlan,
      })
    );

    dispatch(quitViewFlightPlan(projectId, areaId));
  };
}

export function finishAreaPlanningEdition(): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const editingArea = projectTreeState.editingArea;
    if (editingArea === undefined) return;

    if (editingArea.type === EditingAreaType.MissionPlannerRotation) {
      const homePoint = editingArea.homePoint;
      if (homePoint === undefined) return;

      const plannedArea = plannedRouteControlUpdate(editingArea.area.planned, {
        homePoint: homePoint,
        waypoints: editingArea.waypoints,
        basePoint: editingArea.basePoint,
        routeAngle: editingArea.routeAngle,
      });

      if (
        plannedArea.id !== editingArea.area.planned.id ||
        plannedArea.route?.id !== editingArea.area.planned.route?.id
      ) {
        /// We need to update the area
        dispatch(
          updateArea({
            id: editingArea.area.id,
            projectId: editingArea.area.projectId,
            planned: plannedArea,
          })
        );
      }
    } else if (editingArea.type === EditingAreaType.CreatingProject) {
      if (editingArea.polygon.length < 3) return;

      dispatch(
        createProject({
          ...editingArea.creatingProject,
          areas: [
            {
              areaName: editingArea.areaName,
              areaPolygon: editingArea.polygon,
              areaConfig: editingArea.creatingProject.areaConfig,
              configuredReleasers: editingArea.creatingProject.configuredReleasers,
            },
          ],
        })
      );
    } else if (editingArea.type === EditingAreaType.DrawingNewArea) {
      if (editingArea.polygon.length < 3) return;

      dispatch(
        createArea(editingArea.projectId, {
          areaName: editingArea.areaName,
          areaPolygon: editingArea.polygon,
          areaConfig: editingArea.areaConfig,
          configuredReleasers: editingArea.configuredReleasers,
        })
      );
    } else {
      let area = editingArea.area;

      let polygon: Location[] | undefined = undefined;
      let waypoints: Waypoint[] | undefined = undefined;
      let homePoint: Location | undefined = undefined;

      if (
        editingArea.type === EditingAreaType.EditingPolygon ||
        editingArea.type === EditingAreaType.EditingEverything
      ) {
        if (editingArea.polygon.length < 3) {
          return;
        }
        polygon = editingArea.polygon;
      }

      if (
        editingArea.type === EditingAreaType.EditingPlanPoints ||
        editingArea.type === EditingAreaType.EditingEverything
      ) {
        if (editingArea.waypoints.length < 3) {
          return;
        }
        waypoints = editingArea.waypoints;

        const areaConfig = editingArea.area.areaConfig;

        const headingMode = areaConfig.headingMode;

        homePoint = editingArea.homePoint;

        if (headingMode === HeadingMode.nextWaypoint) {
          waypoints = fixHeadingForWaypoints(waypoints, homePoint);
        }
      }

      dispatch(
        manuallyUpdateAreaPlan({
          projectId: area.projectId,
          areaId: area.id,
          polygon: polygon,
          waypoints: waypoints,
          homePoint: homePoint,
        })
      );
    }

    dispatch(closeEditionMode());
  };
}

export function onClickFlightInProjectTree(
  projectId: string,
  areaId: string,
  flightId: string
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const flight = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId)
      ?.flightList?.find((flight) => flight.id === flightId);
    if (flight === undefined) return;

    const plannedPolygon = flight.flightEnvironmentSnapshot.plannedArea.polygon;
    const boundingBox = boundingBoxForAreas([plannedPolygon])!;
    dispatch(moveToBoundingBox(boundingBox));
  };
}

export function copyArea(area: AreaInProjectTree): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_COPIED_AREA,
      payload: {
        copiedArea: area,
      },
    });
  };
}

export function pasteCopiedArea(projectId: string): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const copiedArea = projectTreeState.copiedArea;
    if (copiedArea === undefined) return;

    dispatch({
      type: SET_COPIED_AREA,
      payload: {
        copiedArea: undefined,
      },
    });

    dispatch(_createAreaDuplicateInAProject(areaInProjectTreeToArea(copiedArea), projectId));
  };
}

export function updateDiagonalScreenSize(size: { width: number; height: number }): SystemThunk {
  return async (dispatch) => {
    const diagonal = Math.sqrt(Math.pow(size.height, 2) + Math.pow(size.width, 2));

    dispatch({
      type: UPDATE_DIAGONAL_SCREEN_SIZE,
      payload: {
        diagonalScreenSize: diagonal,
      },
    });

    dispatch(fetchMapBounds());
  };
}

export function setItemsVisible(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_ITEMS_VISIBILITY,
      payload: {
        visibility: true,
      },
    });
  };
}

export function setItemsInvisible(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_ITEMS_VISIBILITY,
      payload: {
        visibility: false,
      },
    });
  };
}

export function exportAsKml(
  projectId: string,
  areaId: string,
  flightId: string | undefined,
  exportKmlTypes: ExportKmlTypes[]
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;
    const stateProject = projectTreeState.projectList?.find((project) => project.id === projectId);
    const stateArea = stateProject?.areas?.find((area) => area.id === areaId);
    const stateFlight =
      flightId !== undefined
        ? stateArea?.flightList?.find((flight) => flight.id === flightId)
        : undefined;
    if (
      stateProject === undefined ||
      stateArea === undefined ||
      (flightId !== undefined && stateFlight === undefined)
    ) {
      return;
    }

    const isExportingArea =
      areaId &&
      (exportKmlTypes.includes(ExportKmlTypes.polygon) ||
        exportKmlTypes.includes(ExportKmlTypes.route));
    const isExportingFlight = flightId && exportKmlTypes.includes(ExportKmlTypes.flight);

    if (isExportingArea) {
      dispatch({
        type: SET_AREA_BEING_EXPORTED_AS_KML,
        payload: {
          projectId: projectId,
          areaId: areaId,
          loading: true,
        },
      });
    }
    if (isExportingFlight) {
      dispatch({
        type: SET_FLIGHT_BEING_EXPORTED_AS_KML,
        payload: {
          projectId: projectId,
          areaId: areaId,
          flightId: flightId!,
          loading: true,
        },
      });
    }
    const exportKmlResult = await KmklService.exportAsKmlFile(
      {
        id: areaId,
        name: stateArea.name,
      },
      flightId !== undefined ? { id: flightId, startedAt: stateFlight!.startedAt } : undefined,
      exportKmlTypes
    );
    if (isExportingArea) {
      dispatch({
        type: SET_AREA_BEING_EXPORTED_AS_KML,
        payload: {
          projectId: projectId,
          areaId: areaId,
          loading: false,
        },
      });
    }
    if (isExportingFlight) {
      dispatch({
        type: SET_FLIGHT_BEING_EXPORTED_AS_KML,
        payload: {
          projectId: projectId,
          areaId: areaId,
          flightId: flightId!,
          loading: false,
        },
      });
    }
    if (exportKmlResult.success === false) {
      dispatch(_dispatchError(exportKmlResult.error));
    }
  };
}

export function setShowingMapLabels(showing: boolean): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_SHOWING_MAP_LABELS,
      payload: {
        showing: showing,
      },
    });
  };
}

export function fetchMapPixelsDimensions(pixelsWidth: number, pixelsHeight: number): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: FETCH_MAP_PIXELS_DIMENSIONS,
      payload: {
        pixelsHeight: pixelsHeight,
        pixelsWidth: pixelsWidth,
      },
    });
  };
}

export function onClickWaypoint(params: {
  projectId: string;
  areaId: string;
  waypointIndex: number;
  openWaypointSettings: (area: Area, waypoint: Waypoint & { index: number }) => void;
  openWaypointsSettings: (area: Area, waypointIndexes: (Waypoint & { index: number })[]) => void;
}): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;
    const pressingCtrlKey = projectTreeState.pressingCtrlKey;

    const relativeAreaAux = projectTreeState.projectList
      ?.find((project) => project.id === params.projectId)
      ?.areas?.find((area) => area.id === params.areaId);
    if (relativeAreaAux === undefined) return;
    const relativeArea = areaInProjectTreeToArea(relativeAreaAux);

    const relativeWaypoint = relativeArea.planned.route?.waypoints.find(
      (_, waypointIndex) => waypointIndex === params.waypointIndex
    );
    if (relativeWaypoint === undefined) return;

    if (pressingCtrlKey) {
      dispatch(
        onTapWaypointPressingControlKey(params.projectId, params.areaId, params.waypointIndex)
      );
    } else {
      const selectedWaypoints = projectTreeState.selectedWaypoints;
      if (selectedWaypoints !== undefined) {
        if (
          selectedWaypoints.areaId !== params.areaId ||
          !selectedWaypoints.waypointIndexes.includes(params.waypointIndex)
        ) {
          dispatch(clearSelectedWaypointIndexes());

          params.openWaypointSettings(relativeArea, {
            ...relativeWaypoint,
            index: params.waypointIndex,
          });
        } else {
          const selectedWaypointsValues: (Waypoint & { index: number })[] = [];
          for (const selectedWaypointIndex of selectedWaypoints.waypointIndexes) {
            const waypoint = relativeArea.planned.route?.waypoints.find(
              (_, index) => index === selectedWaypointIndex
            );
            if (waypoint !== undefined) {
              selectedWaypointsValues.push({
                ...waypoint,
                index: selectedWaypointIndex,
              });
            }
          }

          params.openWaypointsSettings(relativeArea, selectedWaypointsValues);
        }
      } else {
        params.openWaypointSettings(relativeArea, {
          ...relativeWaypoint,
          index: params.waypointIndex,
        });
      }
    }
  };
}

export function onMapClick(params: { location: Location }): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;
    const pressingCtrlKey = projectTreeState.pressingCtrlKey;

    const draggingMarker = projectTreeState.mapState.draggingMarker;
    if (draggingMarker) return;

    const editingArea = projectTreeState.editingArea;
    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (newEditingArea.type === EditingAreaType.MissionPlannerRotation) {
      dispatch(changeEditingAreaMissionPlannerBasePoint(params.location));

      return;
    } else if (
      pressingCtrlKey &&
      (newEditingArea.type === EditingAreaType.EditingPlanPoints ||
        newEditingArea.type === EditingAreaType.EditingEverything)
    ) {
      newEditingArea = {
        ...newEditingArea,
        homePoint: params.location,
      };
    } else {
      if (newEditingArea.type === EditingAreaType.EditingPlanPoints) {
        const newWaypoint = getNewWaypoint(params.location, newEditingArea.area.areaConfig);

        // Check if the point is inside the editing area, if so the on click event of the editing
        // polygon will also be called and we can't add another waypoint because it will be duplicated
        if (!isPointInsidePolygon(newEditingArea.area.planned.polygon, params.location)) {
          newEditingArea = {
            ...newEditingArea,
            waypoints: [...newEditingArea.waypoints, newWaypoint],
          };
        }
      } else {
        // Check if the point is inside the editing area, if so the on click event of the editing
        // polygon will also be called and we can't add a polygon vertex because it will be used
        // to add an area waypoint
        if (!isPointInsidePolygon(newEditingArea.polygon, params.location)) {
          newEditingArea = {
            ...newEditingArea,
            polygon: [...newEditingArea.polygon, params.location],
          };
        }
      }
    }

    dispatch(updateEditingArea(newEditingArea));
  };
}

function getNewWaypoint(location: Location, areaConfig: AreaConfig): Waypoint {
  return {
    location: location,
    curveRadius: 0.2,
    height: areaConfig.flightHeight,
    orientation: 0,
    speed: areaConfig.flightSpeed,
    elevation: 0,
    droneActions: [],
    releaserActions: {},
  };
}

export function onClickWaypointEditingArea(waypointIndex: number): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const draggingMarker = projectTreeState.mapState.draggingMarker;
    if (draggingMarker) return;

    const editingArea = projectTreeState.editingArea;
    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;
    if (
      newEditingArea.type === EditingAreaType.EditingPlanPoints ||
      newEditingArea.type === EditingAreaType.EditingEverything
    ) {
      newEditingArea = {
        ...newEditingArea,
        waypoints: newEditingArea.waypoints.filter((_, index) => index !== waypointIndex),
      };

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function onClickPolygonEditingArea(location: Location): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const draggingMarker = projectTreeState.mapState.draggingMarker;
    if (draggingMarker) return;

    const editingArea = projectTreeState.editingArea;
    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (newEditingArea.type === EditingAreaType.MissionPlannerRotation) {
    } else if (
      newEditingArea.type === EditingAreaType.CreatingProject ||
      newEditingArea.type === EditingAreaType.DrawingNewArea ||
      newEditingArea.type === EditingAreaType.EditingPolygon
    ) {
      newEditingArea = {
        ...newEditingArea,
        polygon: [...newEditingArea.polygon, location],
      };
    } else {
      const newWaypoint = getNewWaypoint(location, newEditingArea.area.areaConfig);

      newEditingArea = {
        ...newEditingArea,
        waypoints: [...newEditingArea.waypoints, newWaypoint],
      };
    }

    dispatch(updateEditingArea(newEditingArea));
  };
}

function onTapWaypointPressingControlKey(
  projectId: string,
  areaId: string,
  waypointIndex: number
): SystemThunk {
  return async (dispatch, getState) => {
    const selectedWaypoints = getState().projectTree.selectedWaypoints;

    if (selectedWaypoints?.areaId === areaId) {
      if (selectedWaypoints.waypointIndexes.includes(waypointIndex)) {
        dispatch({
          type: SET_SELECTED_WAYPOINT_INDEXES,
          payload: {
            projectId: projectId,
            areaId: selectedWaypoints.areaId,
            waypointIndexes: selectedWaypoints.waypointIndexes.filter(
              (value) => value !== waypointIndex
            ),
          },
        });
      } else {
        dispatch({
          type: SET_SELECTED_WAYPOINT_INDEXES,
          payload: {
            projectId: projectId,
            areaId: selectedWaypoints.areaId,
            waypointIndexes: [...selectedWaypoints.waypointIndexes, waypointIndex],
          },
        });
      }
    } else {
      dispatch({
        type: SET_SELECTED_WAYPOINT_INDEXES,
        payload: {
          projectId: projectId,
          areaId: areaId,
          waypointIndexes: [waypointIndex],
        },
      });
    }
  };
}

export function clearSelectedWaypointIndexes(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_WAYPOINT_INDEXES,
      payload: undefined,
    });
  };
}

export function editingAreaOnReverseRoute(): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;
    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (
      newEditingArea.type !== EditingAreaType.EditingEverything &&
      newEditingArea.type !== EditingAreaType.EditingPlanPoints
    )
      return;

    const waypoints = newEditingArea.waypoints;

    if (waypoints.length === 0) return;

    const firstWaypointReleaserActions = waypoints[0].releaserActions;
    const lastWatpointReleaserActions = waypoints[waypoints.length - 1].releaserActions;

    dispatch(
      updateEditingArea({
        ...newEditingArea,
        waypoints: waypoints.reverse().map((waypoint, waypointIndex) => ({
          ...waypoint,
          releaserActions:
            waypointIndex === 0
              ? firstWaypointReleaserActions
              : waypointIndex === waypoints.length - 1
              ? lastWatpointReleaserActions
              : waypoint.releaserActions,
        })),
      })
    );
  };
}

export function rotateMap(newRotation: number): SystemThunk {
  return async (dispatch) => {
    if (baseMapController === null) return;

    await baseMapController.setRotation(newRotation);

    dispatch({
      type: SET_MAP_STATE,
      payload: {
        rotation: newRotation,
      },
    });
  };
}

export function handlePressingCtrlKey(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PRESSING_CTRL_KEY,
      payload: {
        pressing: true,
      },
    });
  };
}

export function handleUnPressCtrlKey(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PRESSING_CTRL_KEY,
      payload: {
        pressing: false,
      },
    });
  };
}

export function enterEditAreaMissionPlannerRotation(
  projectId: string,
  areaId: string
): SystemThunk {
  return async (dispatch, getState) => {
    const projectTreeState = getState().projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);
    if (area === undefined) return;

    const mustReleaseEntireArea = getMustReleaseEntireArea(getState().profile);

    const { routeAngle, basePoint } = getPlannedAreaBasePointAndRouteAngle(area.planned);

    dispatch({
      type: ENTER_EDIT_AREA_MODE,
      payload: {
        type: "rotate-plan",
        area: areaInProjectTreeToArea(area),
        routeAngle: routeAngle,
        ...getMissionPlannerRouteUsingBasePointAndRouteAngle(
          basePoint,
          routeAngle,
          area.planned.polygon,
          area.areaConfig,
          area.configuredReleasers,
          mustReleaseEntireArea
        ),
      },
    });
  };
}

function getPlannedAreaBasePointAndRouteAngle(plannedArea: PlannedArea): {
  basePoint: Location;
  routeAngle: number;
} {
  let basePoint: Location;
  let routeAngle: number;

  if (plannedArea.basePoint !== null && plannedArea.basePoint !== undefined) {
    basePoint = plannedArea.basePoint;
  } else {
    basePoint =
      plannedArea.homePoint ?? plannedArea.route?.waypoints[0].location ?? plannedArea.polygon[0];
  }
  if (plannedArea.routeAngle !== null && plannedArea.routeAngle !== undefined) {
    routeAngle = plannedArea.routeAngle;
  } else {
    const waypoints = plannedArea.route?.waypoints ?? [];
    if (waypoints.length >= 2) {
      const bearing = bearingBetween(waypoints[0].location, waypoints[1].location);

      if (bearing < 270) {
        routeAngle = 90 - bearing;
      } else {
        routeAngle = 90 + 360 - bearing;
      }
    } else {
      routeAngle = 0;
    }
  }

  routeAngle = routeAngle % 180;
  if (routeAngle > 90) {
    routeAngle = 180 - routeAngle;
  }

  return {
    basePoint,
    routeAngle,
  };
}

export function changeEditingAreaMissionPlannerRotation(newRotation: number): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (newEditingArea.type === EditingAreaType.MissionPlannerRotation) {
      const mustReleaseEntireArea = getMustReleaseEntireArea(getState().profile);

      newEditingArea = {
        ...newEditingArea,
        routeAngle: newRotation,
        ...getMissionPlannerRouteUsingBasePointAndRouteAngle(
          newEditingArea.basePoint ??
            getPlannedAreaBasePointAndRouteAngle(newEditingArea.area.planned).basePoint,
          newRotation,
          newEditingArea.area.planned.polygon,
          newEditingArea.area.areaConfig,
          newEditingArea.area.configuredReleasers,
          mustReleaseEntireArea
        ),
      };

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function changeEditingAreaMissionPlannerBasePoint(basePoint: Location): SystemThunk {
  return async (dispatch, getState) => {
    const editingArea = getState().projectTree.editingArea;

    if (editingArea === undefined) return;

    let newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

    if (newEditingArea.type === EditingAreaType.MissionPlannerRotation) {
      const mustReleaseEntireArea = getMustReleaseEntireArea(getState().profile);

      newEditingArea = {
        ...newEditingArea,
        ...getMissionPlannerRouteUsingBasePointAndRouteAngle(
          basePoint,
          newEditingArea.routeAngle,
          newEditingArea.area.planned.polygon,
          newEditingArea.area.areaConfig,
          newEditingArea.area.configuredReleasers,
          mustReleaseEntireArea
        ),
      };

      dispatch(updateEditingArea(newEditingArea));
    }
  };
}

export function setVisualizationMode(mode: VisualizationMode): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_VISUALIZATION_MODE,
      payload: {
        mode: mode,
        consideringSubItems: getState().projectTree.visualizationModeConsideringSubItems,
      },
    });
  };
}

export function setVisualizationModeConsideringSubItems(consideringSubItems: boolean): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_VISUALIZATION_MODE,
      payload: {
        mode: getState().projectTree.visualizationMode,
        consideringSubItems: consideringSubItems,
      },
    });
  };
}

export function expandProjects(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_EXPANDED_PROJECTS,
      payload: {
        expanded: true,
      },
    });
  };
}

export function collapseProjects(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_EXPANDED_PROJECTS,
      payload: {
        expanded: false,
      },
    });
  };
}

export function fetchProjectTreeScrollPosition(position: number): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: FETCH_PROJECT_TREE_SCROLL_POSITION,
      payload: {
        position: position,
      },
    });
  };
}
