import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { Role, UnitSystem } from "biohub-model";
import UnitConversionHelper from "../../../../../core/helper/UnitConversionHelper";

export default (): JSX.Element => {
  const pixelsPerMeter = useSelector(
    (state: SystemState) => {
      const mapState = state.projectTree.mapState;

      return mapState.visibleRegionDiagonalInPixels / mapState.boundingBoxDiagonalSize;
    },
    (a, b) => a.toFixed(18) === b.toFixed(18)
  );
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const dpi = window.devicePixelRatio * 96;

  let pixelsSize: number;
  if (unitSystem === UnitSystem.metric) {
    /// 2cm
    pixelsSize = (dpi / 2.54) * 2;
  } else {
    /// 1 in
    pixelsSize = dpi;
  }
  pixelsSize = Math.floor(pixelsSize);

  const mapScale = getMapScale(pixelsPerMeter, pixelsSize, unitSystem);

  const scaleRuleWidth = 2;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {mapScale}

      <div
        style={{
          height: 5,
          width: scaleRuleWidth,
          backgroundColor: "white",
          borderRadius: 2,
          marginLeft: 5,
        }}
      />
      <div
        style={{
          height: scaleRuleWidth,
          width: pixelsSize - 2 * scaleRuleWidth,
          backgroundColor: "white",
        }}
      />
      <div
        style={{
          height: 5,
          width: scaleRuleWidth,
          backgroundColor: "white",
          borderRadius: 2,
        }}
      />
    </div>
  );
};

const getMapScale = (
  pixelsPerMeter: number,
  pixelsSize: number,
  unitSystem: UnitSystem
): string => {
  const sizeInMeters = pixelsSize / pixelsPerMeter;

  const decimalPrecision = 3;

  if (unitSystem === UnitSystem.metric) {
    if (sizeInMeters < 1000) {
      return numberToNumberOfDecimalPlaces(sizeInMeters, decimalPrecision) + "m";
    } else {
      return numberToNumberOfDecimalPlaces(sizeInMeters / 1000, 0) + "km";
    }
  } else {
    const sizeInFt = sizeInMeters * 3.28084;
    const ftInAMile = 5280;

    if (sizeInFt > ftInAMile) {
      return numberToNumberOfDecimalPlaces(sizeInFt / ftInAMile, decimalPrecision) + "mi";
    } else {
      return numberToNumberOfDecimalPlaces(sizeInFt, 0) + "ft";
    }
  }
};

const numberToNumberOfDecimalPlaces = (n: number, decimalPlaces: number): string => {
  const toString = n.toString();

  let fields = toString.split(".");
  let result = fields.splice(0, 1)[0];

  if (fields.length > 0 && decimalPlaces > 0) {
    fields = fields[0].split("");

    result = result + ".";

    let count = 0;
    while (fields.length > 0 && count < decimalPlaces) {
      result = result + fields.splice(0, 1)[0];
      count++;
    }
  }

  return result;
};
