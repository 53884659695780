export const releaseNotesText = `
## [GMT-3 11/10/2024 16:43]

- Fix disappearing areas when update project

## [GMT-3 10/10/2024 14:58]

- Control map planned area, flight and release tracks layers showing elements in the following order: area polygon, area planned route, flight release segments (green segments) and flight route

## [GMT-3 10/10/2024 09:09]

- Map distance and height values with 2 decimal places like bioMAPS
- Fix on flight report: Planned flight date and hour; Real flight date and hour
- Release estimation on opened area info overlay in the map
- Opened area info overlay showing linear distance of planned route instead of the previous implementation which showed area perimeter

## [GMT-3 02/10/2024 12:10]

- Limit area size in creation to 10 thousand hectares (if it is a square the size would be 10 km by 10 km).
- On select project in project tree go to the bounding box of all project areas together.
- Better map zoom control using percentage.
- Initial map type to satellite
- Restore scroll position in the project tree when reopen

## [GMT-3 27/8/2024 12:09]

- Change data persistence to avoid problems. That change will affect the login, so everyone needs to login again in the website.

- Code fix in the deleted or not deleted projects in the project tree.

- Implementation to fix problem deleting area and other project areas disappearing

- Feature to change if you are seeing not deleted (default option), deleted and all projects and areas in the project tree. You can also mark and option if you want to see and item because a subitem with deleted or not according to the actual view mode. To control that we have a settings button in the project tree ![Project tree control view mode](/release_notes/project_tree_view_modes.png)

## [GMT-3 20/8/2024 09:26]

- New mission planner implementation. After that every new area or new mission planner execution will use the new algorithm. To execute that in a already planned area you can select the "Generate new area route" option in the opened area modal actions like that:
  ![New mission planner generate new area route](/release_notes/new_mission_planner_generate_new_area_route.png)

- New area preferences to control if is an option or not an route outside the polygon to connect different parts and try to make a more efficient flight without a lot of curves. You can see that preference in the project / area settings, or in your profile preferences. You can also see an example of a planned area using that flag enabled
  ![New mission planner can use outside segments in route](/release_notes/new_mission_planner_can_use_outside_segments_in_route.png)

- That new mission planner also improved the home point location when we are using the highest point in the border of the polygon as home point

- New feature to rotate the mission plan
  ![New mission planner rotate mission plan](/release_notes/new_mission_plann_rotate_plan.png)

## [GMT-3 19/8/2024 13:44]

- RELEASE NOTES BEGIN


`;
